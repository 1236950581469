<template>
    <div class="pa-3">
        <v-card flat :loading="loadingView">

            <v-card-text class="items">

                <v-row>
                    <v-col>
                        <v-btn depressed color="grey darken-4" class="white--text" @click="bulkTransferDialog = true">Bulk transfer</v-btn>
                    </v-col>
                </v-row>

                <v-row class="mb-2">
                    <v-col>
                        <v-text-field :value="styleFilter" @input="setStyleFilter" placeholder="Style" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="sizeFilter" @input="setSizeFilter" placeholder="Size" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="barcodeFilter" @input="setBarcodeFilter" placeholder="Barcode" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="serialFilter" @input="setSerialFilter" placeholder="Serial #" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select :value="typeFilter" @input="setTypeFilter" :items="productTypes" placeholder="Item type" item-text="name" item-value="id" flat hide-details solo-inverted clearable @change="loadItems"></v-select>
                    </v-col>
                </v-row>
                <v-data-table :headers="itemHeaders" :items="items" :footer-props="paginationOptions" item-key="id" :options="pagination" :loading="loading">
                    <template v-slot:item.isDeleted="{ item }">
                        <v-chip small label color="error" v-if="item.isDeleted === true">Deleted</v-chip>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-menu bottom left close-on-click>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item @click="openEditDialog( item )">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="openEditSerialDialog( item )">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon small>mdi-key-change</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Change serial</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="openTransferDialog( item )">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon small>mdi-swap-horizontal</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Transfer</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="recoverSelectedItem( item )" v-if="item.isDeleted">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon small color="primary">mdi-keyboard-return</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="primary--text">Recover</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="openDeleteDialog( item )" v-else>
                                    <v-list-item-icon class="mr-2">
                                        <v-icon small color="error">mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="red--text">Delete</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="editDialog" width="500" persistent>
            <v-card>
                <v-card-title>Edit item</v-card-title>
                <v-card-text>
                    <v-simple-table v-if="selected != null">
                        <thead>
                            <tr>
                                <th width="20%"></th>
                                <th width="30%">Current</th>
                                <th width="50%">New</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Serial</th>
                                <td><strong>{{ selected.id }}</strong></td>
                                <td><strong>{{ selected.id }}</strong></td>
                            </tr>
                            <tr>
                                <th>Barcode</th>
                                <td>{{ selected.barcode }}</td>
                                <td>
                                    <v-text-field v-model="barcode" dense placeholder="New barcode" ref="txtBarcode" flat hide-details solo-inverted></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th>Style</th>
                                <td>{{ selected.style }}</td>
                                <td>
                                    <v-text-field v-model="style" dense placeholder="New style" flat hide-details solo-inverted></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th>Size</th>
                                <td>{{ selected.size }}</td>
                                <td>
                                    <v-text-field v-model="size" dense placeholder="New size" flat hide-details solo-inverted></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeEditDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="updateSelectedItem">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editSerialDialog" width="500" persistent>
            <v-card>
                <v-card-title>Change serial</v-card-title>
                <v-card-text>
                    <p>
                        <strong class="error--text">WARNING:</strong> The serial number is a unique identifier of this item. The new serial assigned cannot currently exist.
                    </p>

                    <v-simple-table v-if="selected != null">
                        <thead>
                            <tr>
                                <th width="20%"></th>
                                <th width="30%">Current</th>
                                <th width="50%">New</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Serial</th>
                                <td><strong>{{ selected.id }}</strong></td>
                                <td>
                                    <v-text-field v-model="serial" dense placeholder="New serial" ref="txtSerial" flat hide-details solo-inverted
                                        v-mask="['##########']"></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th>Barcode</th>
                                <td>{{ selected.barcode }}</td>
                                <td>{{ selected.barcode }}</td>
                            </tr>
                            <tr>
                                <th>Style</th>
                                <td>{{ selected.style }}</td>
                                <td>{{ selected.style }}</td>
                            </tr>
                            <tr>
                                <th>Size</th>
                                <td>{{ selected.size }}</td>
                                <td>{{ selected.size }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeEditSerialDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="updateItemSerial">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" width="400" persistent>
            <v-card>
                <v-card-title>Warning!</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item? This action cannot be reversed.

                    <br><br>

                    <div v-if="selected != null">
                        Item barcode: <strong>{{ selected.barcode }}</strong>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="deleteSelectedItem">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="transferDialog" width="500">
            <v-card>
                <v-card-title>Transfer</v-card-title>
                <v-card-text v-if="selected != null">
                    Select the transfer code to apply to this item

                    <br>

                    Item barcode: <strong>{{ selected.barcode }}</strong>

                    <br><br>

                    <v-select v-model="transferStore" :items="[{id: 99, name: 'Warehouse'}, ...stores]" item-value="id" item-text="name" dense 
                        placeholder="Transfer to location" flat hide-details solo-inverted>
                    </v-select>

                    <br>

                    <v-select v-model="transferCode" :items="transferCodes" item-value="id" item-text="code" dense 
                        placeholder="Transfer code" flat hide-details solo-inverted>
                        <template v-slot:item="{ item, on, attrs }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeTransferDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="transferSelectedItem">Transfer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="bulkTransferDialog" width="600" persistent>
            <v-card>
                <v-card-title>
                    Bulk transfer
                </v-card-title>
                <v-card-subtitle>
                    Transfer multiple items at once
                </v-card-subtitle>
                <v-card-text>
                    <v-alert :value="bulkError.length > 0" type="error" dense>{{ bulkError }}</v-alert>
                    <v-form ref="bulkForm">
                        <v-row class="mb-1">
                            <v-col>
                                <v-select v-model="bulkToStore" :items="[{id: 99, name: 'Warehouse'}, ...stores]" item-value="id" item-text="name" dense 
                                    placeholder="Transfer to location" flat hide-details solo-inverted :rules="[(v) => !!v || 'requerido']">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select v-model="bulkTransferCode" :items="transferCodes" item-value="id" item-text="code" dense 
                                    placeholder="Transfer code" flat hide-details solo-inverted :rules="[(v) => !!v || 'requerido']">
                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item v-on="on" v-bind="attrs">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.code }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>

                        <v-textarea v-model="bulkBarcodes" placeholder="Enter one barcode per line" flat hide-details solo-inverted rows="20"
                             :rules="[(v) => !!v || 'requerido']"></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <span class="caption" v-show="bulkBarcodesCount > 0">{{ bulkBarcodesCount }} barcodes</span>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeBulkTransferDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" :loading="loading" @click="transferInBulk">Transfer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="bulkTransferErrorsDialog" width="500">
            <v-card>
                <v-card-title>Bulk transfer errors</v-card-title>
                <v-card-subtitle>These errors arise during the bulk transfer</v-card-subtitle>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Barcode</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(error, ix) in bulkTransferErrors" :key="ix">
                                <td>{{ error.barcode }}</td>
                                <td>{{ error.message }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="bulkTransferErrorsDialog = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';

export default {
    data: () => ({
        itemHeaders                 : [
            { text: 'Style', value: 'style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Barcode', value: 'barcode', sortable: true },
            { text: 'Serial #', value: 'id', sortable: true },
            // { text: 'Order #', value: 'orderNum', sortable: true },
            { text: 'Cur. location', value: 'currentLocation', sortable: true },
            { text: 'Def. location', value: 'defaultLocation', sortable: true },
            // { text: 'Available', value: 'available', sortable: true },
            { text: 'Status', value: 'status', sortable: true },
            // { text: 'Date out', value: 'dateOut', sortable: true },
            // { text: 'Due back', value: 'dateDueBack', sortable: true },
            { text: 'Turns', value: 'turns', sortable: true },
            { text: 'Transfer code', value: 'transferCode.code', sortable: true },
            { text: 'Deleted', value: 'isDeleted', sortable: true },
            // { text: 'Prev. location', value: '', sortable: true },
            { text: 'Date added', value: 'dateCreated', sortable: true },
            { text: 'Date inventoried', value: 'dateInventoried', sortable: true },
            // { text: 'Cleaning bin', value: 'cleaningBin', sortable: true },
            { text: 'Altered', value: 'altered', sortable: true },
            // { text: 'Turns YTD', value: 'turnsYTD', sortable: true },
            // { text: 'Last inspector', value: 'lastInspectorEmployeeId', sortable: true },
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Action', value: 'action', sortable: false }
        ],
        pagination                  : {
            sortBy: ['style']
        },
        paginationOptions           : { itemsPerPageOptions: [ 50, 75, 100, -1 ] },

        loading                     : false,
        loadingView                 : false,
        editDialog                  : false,
        editSerialDialog            : false,
        deleteDialog                : false,
        transferDialog              : false,

        selected                    : null,

        barcode                     : '',
        style                       : '',
        size                        : '',
        transferCode                : '',
        transferStore               : '',
        serial                      : '',

        // bulk transfer codes vars
        bulkTransferDialog          : false,
        bulkBarcodes                : '',
        bulkToStore                 : '',
        bulkTransferCode            : '',
        bulkError                   : '',
        bulkTransferErrorsDialog    : false,
        bulkTransferErrors          : []
    }),
    computed: {
        ...mapGetters({
            stores          : 'stores',
            productTypes    : 'productType/items',
            transferCodes   : 'transferCode/items', 
            items           : 'inventory/management/items',
            styleFilter     : 'inventory/management/styleFilter',
            sizeFilter      : 'inventory/management/sizeFilter',
            barcodeFilter   : 'inventory/management/barcodeFilter',
            serialFilter    : 'inventory/management/serialFilter',
            typeFilter      : 'inventory/management/typeFilter',
        }),
        barcodesToTransfer() {
            return this.bulkBarcodes.split('\n');
        },
        bulkBarcodesCount() {
            return this.barcodesToTransfer.length - 1;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadProductTypes    : 'productType/load',
            loadTransferCodes   : 'transferCode/load',
            loadStores          : 'loadStores',
            refreshItems        : 'inventory/management/loadItems',
            setStyleFilter      : 'inventory/management/setStyleFilter',
            setSizeFilter       : 'inventory/management/setSizeFilter',
            setBarcodeFilter    : 'inventory/management/setBarcodeFilter',
            setSerialFilter     : 'inventory/management/setSerialFilter',
            setTypeFilter       : 'inventory/management/setTypeFilter',

            updateItem          : 'inventory/management/updateItem',
            deleteItem          : 'inventory/management/deleteItem',
            recoverItem         : 'inventory/management/recoverItem',
            transferItem        : 'inventory/management/transferItem',
            updateSerial        : 'inventory/management/updateSerial',
            bulkTransfer        : 'inventory/management/bulkTransfer'
        }),
        async init() {
            try {
                await this.loadProductTypes();
                await this.loadTransferCodes();
                await this.loadStores();
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
        async loadItems() {
            try {
                this.loading = true;
                await this.refreshItems();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
            finally {
                this.setBarcodeFilter('');
            }
        },
        openEditDialog( item ) {
            this.selected = item;
            this.editDialog = true;

            setTimeout(() => {
                this.$refs.txtBarcode.focus();
            }, 100);
        },
        openEditSerialDialog( item ) {
            this.selected = item;
            this.editSerialDialog = true;

            setTimeout(() => {
                this.$refs.txtSerial.focus();
            });
        },
        closeEditDialog() {
            this.editDialog = false;
            setTimeout(() => {
                this.selected = null;
                this.barcode = '';
                this.style = '';
                this.size = '';
            }, 300);
        },
        closeEditSerialDialog() {
            this.editSerialDialog = false;
            setTimeout(() => {
                this.selected = null;
                this.serial = '';
            }, 300);
        },
        openDeleteDialog( item ) {
            this.selected = item;
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            setTimeout(() => {
                this.selected = null;
            }, 300);
        },
        openTransferDialog( item ) {
            this.selected = item;
            this.transferDialog = true;
        },
        closeTransferDialog() {
            this.transferDialog = false;
            setTimeout(() => {
                this.selected = null;
                this.transferCode = null;
            }, 300);
        },
        closeBulkTransferDialog() {
            this.bulkTransferDialog = false;
            this.$refs.bulkForm.reset();

            this.bulkTransferDialog         = false;
            this.bulkBarcodes               = '';
            this.bulkToStore                = '';
            this.bulkTransferCode           = '';
            this.bulkError                  = '';
            this.bulkTransferErrors         = [];
            this.bulkTransferErrorsDialog   = false;
        },
        async updateSelectedItem() {
            if( _st.isNUE( this.selected ) ) {
                this.mxShowMessage( 'Product not set', 'warning' );
                return;
            }

            if( _st.isNUE( this.barcode ) && _st.isNUE( this.style ) && _st.isNUE( this.size ) ) {
                this.mxShowMessage( 'You must provide at least one value to be updated', 'warning' );
                return;
            }

            try {
                this.loading = true;
                await this.updateItem({
                    itemId  : this.selected.id,
                    barcode : this.barcode,
                    style   : this.style,
                    size    : this.size
                });
                this.loading = false;

                this.mxShowMessage('Item updated', 'success');
                this.closeEditDialog();
            }
            catch(error) {
                this.loading = false;
            }
        },
        async updateItemSerial() {
            if( _st.isNUE( this.selected ) ) {
                this.mxShowMessage( 'Product not set', 'warning' );
                return;
            }

            if( _st.isNUE( this.serial ) ) {
                this.mxShowMessage( 'You must provide the new serial', 'warning' );
                return;
            }

            try {
                this.loading = true;
                await this.updateSerial({
                    itemId  : this.selected.id,
                    serial  : this.serial,
                });

                this.mxShowMessage('Serial updated', 'success');
                this.closeEditSerialDialog();
            }
            catch(error) {
                console.log(error);
            }
            finally {
                this.loading = false;
            }
        },
        async deleteSelectedItem() {
            if( _st.isNUE( this.selected ) ) {
                this.mxShowMessage( 'Item not set', 'warning' );
                return;
            }

            try {
                this.loading = true;
                await this.deleteItem( this.selected.id );
                this.loading = false;

                this.closeDeleteDialog();
            }
            catch(error) {
                this.loading = false;
            }
        },
        async recoverSelectedItem( item ) {
            if( _st.isNUE( item ) ) {
                this.mxShowMessage( 'Item not set', 'warning' );
                return;
            }

            try {
                this.loading = true;
                await this.recoverItem( item.id );
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        transferSelectedItem() {
            if( _st.isNUE( this.selected ) ) {
                this.mxShowMessage( 'Item not set', 'warning' );
                return;
            }

            try {
                let code = this.transferCodes.find(i => i.id == this.transferCode);

                this.loading = true;
                this.transferItem({ 
                    itemId      : this.selected.id,
                    locationId  : this.transferStore,
                    code        : code
                });
                this.loading = false;

                this.closeTransferDialog();
            }
            catch(error) {
                this.loading = false;
            }
        },
        async transferInBulk() {
            this.bulkError = '';
            if( !this.$refs.bulkForm.validate() ) {
                this.bulkError = 'All fields are required';
                return;
            }


            let barcodes = [];

            // trim values and remove empty lines
            this.barcodesToTransfer.forEach(b => {
                if( _st.isNUE( b ) )
                    return;

                barcodes.push( b.trim() );
            });

            try {
                this.loading = true;

                let code = this.transferCodes.find(i => i.id == this.bulkTransferCode);

                let errors = await this.bulkTransfer({
                    barcodes,
                    code,
                    locationId: this.bulkToStore,
                });

                if( !_st.isNUE( errors ) ) {
                    this.bulkTransferErrors = errors;
                    this.bulkTransferErrorsDialog = true;
                    return;
                }

                this.closeBulkTransferDialog();
            }
            finally {
                this.loading = false;
            }
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>